import type { Dispatch, Commit } from 'vuex';
import {
	NAMESPACE,
	ActionTypes,
	MutationTypes,
	LIBRARY_EXPORT_TYPE,
	PixcapLibraryFileDetails,
	LibraryFilter,
	PixcapLibraryFile,
	PixcapLibraryPack,
	PackFilter,
	SEARCH_TAB,
	SEARCH_ITEM_TYPE,
} from '@/models/store/pixcapassets.interface';
import { LIBRARY_RESOURCE_TYPE } from '@pixcap/ui-types/enums/library.enums';

export const actionsWrapper = {
	viewLibraryItem({ dispatch }: { dispatch: Dispatch }, itemId: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.VIEW_LIBRARY_ITEM}`, itemId, { root: true });
	},
	exportLibraryItem({ dispatch }: { dispatch: Dispatch }, itemId: string, type: LIBRARY_EXPORT_TYPE) {
		return dispatch(`${NAMESPACE}/${ActionTypes.EXPORT_LIBRARY_ITEM}`, { itemId, type }, { root: true });
	},
	fetchLibraryFiles(
		{ dispatch }: { dispatch: Dispatch },
		payload: {
			refresh: boolean;
			page: number;
			pageSize: number;
			itemType?: string | string[];
			category?: string;
			search?: string;
			tagSearch?: string;
			resourceType?: string;
			isPro?: boolean;
			isAnimated?: boolean;
			topic?: string;
			// thumbnail filters
			colour?: string;
			format?: string;
			minHeight?: number;
			maxHeight?: number;
			minWidth?: number;
			maxWidth?: number;
			skipStore?: boolean;
		}
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_LIBRARY_FILES}`, payload, { root: true });
	},
	fetchLibraryFileDetails({ dispatch }: { dispatch: Dispatch }, itemIdentifier: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_LIBRARY_FILE_DETAILS}`, itemIdentifier, { root: true });
	},
	fetchPackItems({ dispatch }: { dispatch: Dispatch }, itemId: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_PACK_ITEMS}`, itemId, { root: true });
	},
	fetchPacksList(
		{ dispatch }: { dispatch: Dispatch },
		payload: {
			refresh?: boolean;
			page?: number;
			pageSize?: number;
			category?: string;
			search?: string;
			isPro?: boolean;
			hasAnimated?: boolean;
			resourceTypes?: string[];
			tagSearch?: string;
			isBrandKit?: boolean;
			isCurated?: boolean;
			skipStore?: boolean;
			contributorId?: string;
		}
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_PACKS_LIST}`, payload, { root: true });
	},

	fetchSuggestedGroupedPacksList(
		{ dispatch }: { dispatch: Dispatch },
		payload: {
			groupSize?: number;
			search?: string;
			isPro?: boolean;
			hasAnimated?: boolean;
		}
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_SUGGESTED_GROUPED_PACK_LIST}`, payload, { root: true });
	},

	fetchTemplatesList(
		{ dispatch }: { dispatch: Dispatch },
		payload: {
			refresh?: boolean;
			page?: number;
			pageSize?: number;
			tagSearch?: string;
			search?: string;
			isPro?: boolean;
			topic?: string;
			topicIncluded?: boolean;
			itemType?: string[];
			maxWidth?: number;
			maxHeight?: number;
		}
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_TEMPLATES_LIST}`, payload, { root: true });
	},
	fetchLibraryTemplateDetails({ dispatch }: { dispatch: Dispatch }, packSlug: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_LIBRARY_TEMPLATE_DETAILS}`, packSlug, { root: true });
	},
	fetchLibraryPackDetails({ dispatch }: { dispatch: Dispatch }, packSlug: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_LIBRARY_PACK_DETAILS}`, packSlug, { root: true });
	},
	fetchLibrarySearchSuggestions(
		{ dispatch }: { dispatch: Dispatch },
		payload: { size: number; itemType?: string; resourceType?: string; search: string; cancelToken? }
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_LIBRARY_SEARCH_SUGGESTIONS}`, payload, { root: true });
	},

	fetchRelatedModels(
		{ dispatch }: { dispatch: Dispatch },
		search: string,
		ignoreIds?: string[],
		ignorePackId?: string,
		background?: string,
		maxHeight?: number,
		maxWidth?: number,
		format?: string,
		resourceType?: LIBRARY_RESOURCE_TYPE[]
	) {
		return dispatch(
			`${NAMESPACE}/${ActionTypes.FETCH_RELATED_MODELS}`,
			{ search, ignoreIds, ignorePackId, background, maxHeight, maxWidth, format, resourceType },
			{ root: true }
		);
	},
	fetchRelatedTemplates(
		{ dispatch }: { dispatch: Dispatch },
		payload: { search?: string; ignoreIds?: string[]; ignorePackId?: string; topic?: string; itemType?: string }
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_RELATED_TEMPLATES}`, payload, { root: true });
	},
	fetchRelatedPacks({ dispatch }: { dispatch: Dispatch }, payload: { search: string; isBrandkit?: boolean; isCharacterPack?: boolean }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_RELATED_PACKS}`, payload, { root: true });
	},
	fetchPresignedFile({ dispatch }: { dispatch: Dispatch }, fileId: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_PRESIGNED_FILE}`, fileId, { root: true });
	},
	setLibraryStateFromRoute({ dispatch }: { dispatch: Dispatch }, routeContext: any) {
		return dispatch(`${NAMESPACE}/${ActionTypes.SET_LIBRARY_STATE_FROM_ROUTE}`, routeContext, { root: true });
	},

	fetchUsecaseTopics({ dispatch }: { dispatch: Dispatch }, keepInStore?: boolean) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_USECASE_TOPICS}`, keepInStore, { root: true });
	},
	fetchTopicsByDesignSize({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_TOPICS_BY_SIZE}`, null, { root: true });
	},
	fetchTopicTag({ dispatch }: { dispatch: Dispatch }, topicId?: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_USECASE_TOPIC_TAGS}`, topicId, { root: true });
	},
	fetchTags({ dispatch }: { dispatch: Dispatch }, payload: { itemType?: string[] }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_TAGS}`, payload, { root: true });
	},
	fetchSuggestedKeywords({ dispatch }: { dispatch: Dispatch }, resourceType?: LIBRARY_RESOURCE_TYPE) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_SUGGESTED_KEYWORDS}`, resourceType, { root: true });
	},
	fetchRecentSearchKeywords({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_RECENT_SEARCH_KEYWORDS}`, null, { root: true });
	},
	fetchRelatedKeywords({ dispatch }: { dispatch: Dispatch }, payload: { keyword: string; type: string }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_RELATED_KEYWORDS}`, payload, { root: true });
	},
	updateRecentSearchKeywords({ dispatch }: { dispatch: Dispatch }, payload: { keyword: string; action: string }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.UPDATE_RECENT_SEARCH_KEYWORDS}`, payload, { root: true });
	},
};

export const mutationsWrapper = {
	setExplorerTab({ commit }: { commit: Commit }, activeTab: string) {
		commit(`${NAMESPACE}/${MutationTypes.SET_ACTIVE_EXPLORER_TAB}`, activeTab, { root: true });
	},
	setSearchItemType({ commit }: { commit: Commit }, activeType: SEARCH_ITEM_TYPE) {
		commit(`${NAMESPACE}/${MutationTypes.SET_ACTIVE_SEARCH_ITEM_TYPE}`, activeType, { root: true });
	},
	setSearchItemResourceType({ commit }: { commit: Commit }, activeType: LIBRARY_RESOURCE_TYPE) {
		commit(`${NAMESPACE}/${MutationTypes.SET_ACTIVE_SEARCH_ITEM_RESOURCE_TYPE}`, activeType, { root: true });
	},

	updateLibraryFilter({ commit }: { commit: Commit }, payload: { stateKey: string; libraryFilter: LibraryFilter }) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_LIBRARY_FILTER}`, payload, { root: true });
	},
	updatePackFilter({ commit }: { commit: Commit }, packFilter: PackFilter) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_PACK_FILTER}`, packFilter, { root: true });
	},
	updateTemplateFilter({ commit }: { commit: Commit }, libraryFilter: LibraryFilter) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_TEMPLATE_FILTER}`, libraryFilter, { root: true });
	},

	updateLibrarySearch({ commit }: { commit: Commit }, search: string) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_LIBRARY_SEARCH}`, search, { root: true });
	},
	updateShowOnlyAnimatedFiles({ commit }: { commit: Commit }, showAnimated: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_SHOW_ONLY_ANIMATED_FILES}`, showAnimated, { root: true });
	},
	updateFilterDesignSize({ commit }: { commit: Commit }, size: string) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_FILTER_DESIGN_SIZE}`, size, { root: true });
	},
	updateShowOnlyProFiles({ commit }: { commit: Commit }, isPro: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_SHOW_ONLY_PRO_FILES}`, isPro, { root: true });
	},
	updateShowOnlyProPacks({ commit }: { commit: Commit }, isPro: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_SHOW_ONLY_PRO_PACKS}`, isPro, { root: true });
	},
	updateShowPackOrElement({ commit }: { commit: Commit }, showPackOrElement: SEARCH_TAB) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_SHOW_PACK_OR_ELEMENT}`, showPackOrElement, { root: true });
	},
	setIsFetchingSuggestions({ commit }: { commit: Commit }, isLoadingSearchSuggestions: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_FETCHING_SEARCH_SUGGESTIONS}`, isLoadingSearchSuggestions, { root: true });
	},
	updateSuggestionsList({ commit }: { commit: Commit }, searchSuggestions: string[]) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_SEARCH_SUGGESTIONS}`, searchSuggestions, { root: true });
	},

	setIsFetchingLibraryFiles({ commit }: { commit: Commit }, payload: { stateKey: string | string[]; isLoading: boolean }) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_FETCHING_LIBRARY_FILES}`, payload, { root: true });
	},
	updateLibraryFilesState(
		{ commit }: { commit: Commit },
		payload: {
			stateKey: string | string[];
			currentPage: number;
			totalPages: number;
			pageSize: number;
			totalItems: number;
			listOfItems: PixcapLibraryFile[];
		}
	) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_LIBRARY_FILES_STATE}`, payload, { root: true });
	},
	resetLibraryFilesState({ commit }: { commit: Commit }, stateKey: string | string[]) {
		commit(`${NAMESPACE}/${MutationTypes.RESET_LIBRARY_FILES_STATE}`, stateKey, { root: true });
	},

	setIsFetchingLibraryPacks({ commit }: { commit: Commit }, isLoading: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_FETCHING_LIBRARY_PACKS}`, isLoading, { root: true });
	},
	updateLibraryPacksState(
		{ commit }: { commit: Commit },
		payload: {
			currentPage: number;
			totalPages: number;
			pageSize: number;
			totalItems: number;
			listOfItems: PixcapLibraryPack[];
		}
	) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_LIBRARY_PACKS_STATE}`, payload, { root: true });
	},
	resetLibraryPacksState({ commit }: { commit: Commit }) {
		commit(`${NAMESPACE}/${MutationTypes.RESET_LIBRARY_PACKS_STATE}`, undefined, { root: true });
	},

	// Library Templates
	setIsFetchingLibraryTemplates({ commit }: { commit: Commit }, isLoading: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_FETCHING_LIBRARY_TEMPLATES}`, isLoading, { root: true });
	},
	updateLibraryTemplatesState(
		{ commit }: { commit: Commit },
		payload: {
			currentPage: number;
			totalPages: number;
			pageSize: number;
			totalItems: number;
			listOfItems: PixcapLibraryFile[];
		}
	) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_LIBRARY_TEMPLATES_STATE}`, payload, { root: true });
	},
	resetLibraryTemplatesState({ commit }: { commit: Commit }) {
		commit(`${NAMESPACE}/${MutationTypes.RESET_LIBRARY_TEMPLATES_STATE}`, undefined, { root: true });
	},
	setIsFetchingTemplateDetails({ commit }: { commit: Commit }, isLoading: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_FETCHING_TEMPLATE_DETAILS}`, isLoading, { root: true });
	},
	updateTemplateDetailsState({ commit }: { commit: Commit }, templateDetails: PixcapLibraryFile) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_TEMPLATE_DETAILS_STATE}`, templateDetails, { root: true });
	},
	setSelectedLibraryTemplateSlug({ commit }: { commit: Commit }, selectedLibraryTemplateSlug: string) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SELECTED_LIBRARY_TEMPLATE_SLUG}`, selectedLibraryTemplateSlug, { root: true });
	},

	// Library Scenes
	setIsFetchingLibraryScenes({ commit }: { commit: Commit }, isLoading: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_FETCHING_LIBRARY_SCENES}`, isLoading, { root: true });
	},
	updateLibraryScenesState(
		{ commit }: { commit: Commit },
		payload: {
			currentPage: number;
			totalPages: number;
			pageSize: number;
			totalItems: number;
			listOfItems: PixcapLibraryFile[];
		}
	) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_LIBRARY_SCENES_STATE}`, payload, { root: true });
	},
	resetLibraryScenesState({ commit }: { commit: Commit }) {
		commit(`${NAMESPACE}/${MutationTypes.RESET_LIBRARY_SCENES_STATE}`, undefined, { root: true });
	},

	setIsFetchingPackDetails({ commit }: { commit: Commit }, isLoading: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_FETCHING_PACK_DETAILS}`, isLoading, { root: true });
	},
	updatePackDetailsState({ commit }: { commit: Commit }, packDetails: PixcapLibraryPack) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_PACK_DETAILS_STATE}`, packDetails, { root: true });
	},
	setIsFetchingRelatedModels({ commit }: { commit: Commit }, isLoading: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_FETCHING_RELATED_MODELS}`, isLoading, { root: true });
	},
	updateRelatedModels({ commit }: { commit: Commit }, relatedModels: PixcapLibraryFile[]) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_RELATED_MODELS}`, relatedModels, { root: true });
	},

	setIsFetchingRelatedTemplates({ commit }: { commit: Commit }, isLoading: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_FETCHING_RELATED_TEMPLATES}`, isLoading, { root: true });
	},
	updateRelatedTemplates({ commit }: { commit: Commit }, relatedTemplates: PixcapLibraryFile[]) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_RELATED_TEMPLATES}`, relatedTemplates, { root: true });
	},

	setIsFetchingRelatedPacks({ commit }: { commit: Commit }, isLoading: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_FETCHING_RELATED_PACKS}`, isLoading, { root: true });
	},
	updateRelatedPacks({ commit }: { commit: Commit }, relatedPacks: PixcapLibraryPack[]) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_RELATED_PACKS}`, relatedPacks, { root: true });
	},

	setIsFetchingItemDetails({ commit }: { commit: Commit }, isLoading: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_FETCHING_ITEM_DETAILS}`, isLoading, { root: true });
	},
	updateLibraryItemDetails({ commit }: { commit: Commit }, itemDetails: PixcapLibraryFileDetails) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_LIBRARY_ITEM_DETAILS}`, itemDetails, { root: true });
	},
	setSelectedLibraryItemSlug({ commit }: { commit: Commit }, selectedLibraryItemSlug: string) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SELECTED_LIBRARY_ITEM_SLUG}`, selectedLibraryItemSlug, { root: true });
	},

	// TOPICS
	updateUsecaseTopics({ commit }: { commit: Commit }, topics) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_USECASE_TOPICS}`, topics, { root: true });
	},

	updateSearchPagePackCount({ commit }: { commit: Commit }, count: number) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_SEARCH_PAGE_PACK_COUNT}`, count, { root: true });
	},
	resetSearchPagePackCount({ commit }: { commit: Commit }) {
		commit(`${NAMESPACE}/${MutationTypes.RESET_SEARCH_PAGE_PACK_COUNT}`, undefined, { root: true });
	},

	updateSearchPageItemCount({ commit }: { commit: Commit }, count: number) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_SEARCH_PAGE_ITEM_COUNT}`, count, { root: true });
	},
	resetSearchPageItemCount({ commit }: { commit: Commit }) {
		commit(`${NAMESPACE}/${MutationTypes.RESET_SEARCH_PAGE_ITEM_COUNT}`, undefined, { root: true });
	},

	setShouldHideSearchPageSuggestedPacks({ commit }: { commit: Commit }, isHide: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SHOULD_HIDE_SEARCH_PAGE_SUGGESTED_PACKS}`, isHide, { root: true });
	},
};
