import { ALL_APP_LOCALES, DEFAULT_APP_LOCALE } from '@/constants/app.constants';

export default ({ app, route }) => {
	const isBlogPage = route.meta.some((meta) => meta.isBlogRoute);
	const excludedRoute = (route.name === 'LibraryPage' && !!route.query.tag) || isBlogPage;
	const routeLocaleParams = route.params.locale || DEFAULT_APP_LOCALE;
	const isRouteSupportLocale = route.matched?.some((record) => record.path.includes(':locale'));
	app.head.htmlAttrs = {
		lang: routeLocaleParams,
	};
	const appLocaleObj = ALL_APP_LOCALES.find((locale) => locale.locale_code === routeLocaleParams);
	if (appLocaleObj) {
		app.head.meta.push({
			hid: 'og:locale',
			name: 'og:locale',
			content: appLocaleObj.og_locale_code,
		});
	}

	if (isRouteSupportLocale && !excludedRoute) {
		ALL_APP_LOCALES.forEach((locale) => {
			const localisedRoute = app.router.resolve({
				...route,
				params: {
					...route.params,
					locale: locale.locale_code !== DEFAULT_APP_LOCALE ? locale.locale_code : null,
					byPassPixcapRouter: 'true',
				},
			});
			if (!localisedRoute) return;
			app.head.link.push({
				rel: 'alternate',
				hreflang: locale.locale_code,
				href: process.env.ROOT_URL + localisedRoute.href,
			});

			if (locale.locale_code === DEFAULT_APP_LOCALE) {
				app.head.link.push({
					rel: 'alternate',
					hreflang: 'x-default',
					href: process.env.ROOT_URL + localisedRoute.href,
				});
			}
		});
	}
};
