import type { ActionContext } from 'vuex';
import { httpClient } from '@pixcap/ui-core/services/httpclient/HttpClient';
import { API_PATHS } from '@pixcap/ui-core/constants/api.constants';
import { ActionTypes, IContributorState } from '@/models/store/contributor.interface';
import { mutationsWrapper } from '@/store/contributor/wrapper';
import logger from '@pixcap/ui-core/helpers/logger';
import i18n from '@/config/i18n';

const ASSETMANAGER_PATH = API_PATHS.ASSETMANAGER_PATH;

export default {
	async [ActionTypes.GET_CONTRIBUTOR_INFO](context: ActionContext<IContributorState, any>, slug) {
		mutationsWrapper.setIsFetchingContributorInfo(context, true);
		mutationsWrapper.setContributorInfo(context, null);
		try {
			let response;
			if (slug === 'pixcap') {
				response = {
					contributorId: 'pixcap',
					avatarThumbnailUrl: `${process.env.ROOT_URL}/cdn/app/pixcap-avatar.webp`,
					displayName: i18n.t('page.contributor_profile.pixcap.display_name'),
					contributorSlug: 'pixcap',
					description: i18n.t('page.contributor_profile.pixcap.description'),
					interests: [
						{
							linkTagId: 'mock-tag-ui-ux-3d-icons',
							tagName: 'UI/UX 3D Icons',
							type: 'contributor_tag',
							url: '/elements/ui%2Fux',
						},
						{
							linkTagId: 'mock-tag-casual-3d-characters',
							tagName: 'Casual 3D Characters',
							type: 'contributor_tag',
							url: '/3d-characters/casual',
						},
						{
							linkTagId: 'mock-tag-fintech-3d-icons',
							tagName: 'Fintech 3D Icons',
							type: 'contributor_tag',
							url: '/elements/fintech',
						},
						{
							linkTagId: 'mock-tag-device-mockups',
							tagName: 'Device mockups',
							type: 'contributor_tag',
							url: '/mockups/device',
						},
						{
							linkTagId: 'mock-tag-branding-mockups',
							tagName: 'Branding mockups',
							type: 'contributor_tag',
							url: '/mockups/branding',
						},
						{
							linkTagId: 'mock-tag-graphic-3d-icons',
							tagName: 'Graphic 3D Icons',
							type: 'contributor_tag',
							url: '/elements/graphic',
						},

						{
							linkTagId: 'mock-tag-business-3d-characters',
							tagName: 'Business 3D Characters',
							type: 'contributor_tag',
							url: '/3d-characters/business',
						},
						{
							linkTagId: 'mock-tag-commerce-3d-icons',
							tagName: 'Commerce 3D Icons',
							type: 'contributor_tag',
							url: '/elements/commerce',
						},
					],
					websiteUrl: 'https://pixcap.com',
					twitterUrl: 'https://x.com/pixcap_app',
					instagramUrl: 'https://www.instagram.com/pixcap_app',
				};
				mutationsWrapper.setContributorInfo(context, response);
			} else {
				response = await httpClient.get(`${ASSETMANAGER_PATH}/contributors/profile/${slug}`);
				if (response) {
					mutationsWrapper.setContributorInfo(context, response.data);
				}
			}
		} catch (e) {
			logger.log('Error while getting contributor profile', e);
		} finally {
			mutationsWrapper.setIsFetchingContributorInfo(context, false);
		}
	},
};
